import * as React from "react";
import "./user.scss"

interface Props {

}

const COMPONENT_NAME = ""

export const User: React.FunctionComponent<Props> = (props) => {
    return (
      <div className={COMPONENT_NAME}>
          User page
      </div>
    );
}